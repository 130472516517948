export const state = () => ({
    showDebugMessages: false,
    windowWidth: 0,
    scrollY: 0,
});

export const mutations = {
    setShowDebugMessages(state, payload) {
        state.showDebugMessages = payload;
    },
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
};
