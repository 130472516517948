//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'CookieBanner',
    props: {
        isCompact: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            areCheckboxesVisible: false,
            model: {
                necessary: true,
                statistics: true,
                marketing: true,
                preferences: true,
            },
        };
    },
    watch: {
        '$store.state.cookie.isCookieBannerVisible'(value) {
            // Load saved values from local storage every time the banner opens
            if (value) {
                const savedConsent = JSON.parse(localStorage.getItem('consent'));

                if (!savedConsent) {
                    return;
                }

                // At this point we know that the user is not setting their preferences for the first time,
                // they want to update them, so we display the checkboxes by default
                this.areCheckboxesVisible = true;

                // restore checkbox states
                this.model.statistics = !!savedConsent?.statistics;
                this.model.marketing = !!savedConsent?.marketing;
                this.model.preferences = !!savedConsent?.preferences;
            }
        },
    },
    mounted() {
        // If no stored consent preferences are found, open the banner
        const savedConsent = JSON.parse(localStorage.getItem('consent'));

        if (!savedConsent) {
            setTimeout(() => {
                this.$store.dispatch('cookie/openBanner');
            }, 1000);
        }
    },
    methods: {
        acceptAll() {
            this.model.statistics = true;
            this.model.marketing = true;
            this.model.preferences = true;
            this.updateSelection();
        },
        denyAll() {
            this.model.statistics = false;
            this.model.marketing = false;
            this.model.preferences = false;
            this.updateSelection();
        },
        updateSelection() {
            // Cookie consent types by categories

            // Necessary
            // - security_storage, functionality_storage
            // since we classify them as mandatory, we do not update their value in the payload

            // Statistics
            // - analytics_storage

            // Preferences
            // - personalization_storage

            // Marketing
            // - ad_storage, ad_user_data, ad_personalization

            // Create a payload from the preference input values consumable by Google Tag Manager
            const payload = {};

            const { statistics, preferences, marketing } = this.model;

            // payload.security_storage = necessary ? 'granted' : 'denied';
            // payload.functionality_storage = necessary ? 'granted' : 'denied';
            payload.analytics_storage = statistics ? 'granted' : 'denied';
            payload.personalization_storage = preferences ? 'granted' : 'denied';
            payload.ad_storage = marketing ? 'granted' : 'denied';
            payload.ad_user_data = marketing ? 'granted' : 'denied';
            payload.ad_personalization = marketing ? 'granted' : 'denied';

            // Update Google Tag Manager with tOhe selected preferences
            this.$gtag('consent', 'update', payload);

            localStorage.setItem('consent', JSON.stringify(this.model));

            setTimeout(() => {
                this.$store.dispatch('cookie/closeBanner');
            }, 100);
        },
    },
};
