import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7767c4c8 = () => interopDefault(import('../pages/accept-invitation.vue' /* webpackChunkName: "pages/accept-invitation" */))
const _5ec47e94 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _4bfb39b2 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _49d6a46a = () => interopDefault(import('../pages/days-off.vue' /* webpackChunkName: "pages/days-off" */))
const _a6b4de18 = () => interopDefault(import('../pages/days-off-calculator.vue' /* webpackChunkName: "pages/days-off-calculator" */))
const _316a5bf0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _568237ba = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _058e7713 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _09dc01f2 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _97822b48 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _32dccf69 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _4af4f990 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _2ca36269 = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _ae39462c = () => interopDefault(import('../pages/company/administrators.vue' /* webpackChunkName: "pages/company/administrators" */))
const _8f3ccbde = () => interopDefault(import('../pages/company/employees.vue' /* webpackChunkName: "pages/company/employees" */))
const _70fe8350 = () => interopDefault(import('../pages/company/export.vue' /* webpackChunkName: "pages/company/export" */))
const _08857478 = () => interopDefault(import('../pages/company/groups.vue' /* webpackChunkName: "pages/company/groups" */))
const _0a70fed2 = () => interopDefault(import('../pages/company/non-working-days.vue' /* webpackChunkName: "pages/company/non-working-days" */))
const _7c2e4447 = () => interopDefault(import('../pages/company/settings.vue' /* webpackChunkName: "pages/company/settings" */))
const _1fc4cde3 = () => interopDefault(import('../pages/register/company.vue' /* webpackChunkName: "pages/register/company" */))
const _7e26fcae = () => interopDefault(import('../pages/register/personal-information.vue' /* webpackChunkName: "pages/register/personal-information" */))
const _00286832 = () => interopDefault(import('../pages/register/team.vue' /* webpackChunkName: "pages/register/team" */))
const _13796225 = () => interopDefault(import('../pages/user/account-settings.vue' /* webpackChunkName: "pages/user/account-settings" */))
const _61f95bdc = () => interopDefault(import('../pages/user/approvers.vue' /* webpackChunkName: "pages/user/approvers" */))
const _95440f4a = () => interopDefault(import('../pages/user/days-off.vue' /* webpackChunkName: "pages/user/days-off" */))
const _5cab7216 = () => interopDefault(import('../pages/user/export.vue' /* webpackChunkName: "pages/user/export" */))
const _37f21481 = () => interopDefault(import('../pages/user/personal-information.vue' /* webpackChunkName: "pages/user/personal-information" */))
const _9b561470 = () => interopDefault(import('../pages/user/_id.vue' /* webpackChunkName: "pages/user/_id" */))
const _0fa9865a = () => interopDefault(import('../pages/user/_id/account-settings.vue' /* webpackChunkName: "pages/user/_id/account-settings" */))
const _37805947 = () => interopDefault(import('../pages/user/_id/approvers.vue' /* webpackChunkName: "pages/user/_id/approvers" */))
const _279c2190 = () => interopDefault(import('../pages/user/_id/days-off.vue' /* webpackChunkName: "pages/user/_id/days-off" */))
const _914736ea = () => interopDefault(import('../pages/user/_id/export.vue' /* webpackChunkName: "pages/user/_id/export" */))
const _cb49d394 = () => interopDefault(import('../pages/user/_id/personal-information.vue' /* webpackChunkName: "pages/user/_id/personal-information" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accept-invitation",
    component: _7767c4c8,
    name: "accept-invitation___hu___default"
  }, {
    path: "/change-password",
    component: _5ec47e94,
    name: "change-password___hu___default"
  }, {
    path: "/dashboard",
    component: _4bfb39b2,
    name: "dashboard___hu___default"
  }, {
    path: "/days-off",
    component: _49d6a46a,
    name: "days-off___hu___default"
  }, {
    path: "/days-off-calculator",
    component: _a6b4de18,
    name: "days-off-calculator___hu___default"
  }, {
    path: "/en",
    component: _316a5bf0,
    name: "index___en"
  }, {
    path: "/forgot-password",
    component: _568237ba,
    name: "forgot-password___hu___default"
  }, {
    path: "/help",
    component: _058e7713,
    name: "help___hu___default"
  }, {
    path: "/hu",
    component: _316a5bf0,
    name: "index___hu"
  }, {
    path: "/login",
    component: _09dc01f2,
    name: "login___hu___default"
  }, {
    path: "/logout",
    component: _97822b48,
    name: "logout___hu___default"
  }, {
    path: "/privacy-policy",
    component: _32dccf69,
    name: "privacy-policy___hu___default"
  }, {
    path: "/register",
    component: _4af4f990,
    name: "register___hu___default"
  }, {
    path: "/terms-of-use",
    component: _2ca36269,
    name: "terms-of-use___hu___default"
  }, {
    path: "/company/administrators",
    component: _ae39462c,
    name: "company-administrators___hu___default"
  }, {
    path: "/company/employees",
    component: _8f3ccbde,
    name: "company-employees___hu___default"
  }, {
    path: "/company/export",
    component: _70fe8350,
    name: "company-export___hu___default"
  }, {
    path: "/company/groups",
    component: _08857478,
    name: "company-groups___hu___default"
  }, {
    path: "/company/non-working-days",
    component: _0a70fed2,
    name: "company-non-working-days___hu___default"
  }, {
    path: "/company/settings",
    component: _7c2e4447,
    name: "company-settings___hu___default"
  }, {
    path: "/en/accept-invitation",
    component: _7767c4c8,
    name: "accept-invitation___en"
  }, {
    path: "/en/change-password",
    component: _5ec47e94,
    name: "change-password___en"
  }, {
    path: "/en/dashboard",
    component: _4bfb39b2,
    name: "dashboard___en"
  }, {
    path: "/en/days-off",
    component: _49d6a46a,
    name: "days-off___en"
  }, {
    path: "/en/days-off-calculator",
    component: _a6b4de18,
    name: "days-off-calculator___en"
  }, {
    path: "/en/forgot-password",
    component: _568237ba,
    name: "forgot-password___en"
  }, {
    path: "/en/help",
    component: _058e7713,
    name: "help___en"
  }, {
    path: "/en/login",
    component: _09dc01f2,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _97822b48,
    name: "logout___en"
  }, {
    path: "/en/privacy-policy",
    component: _32dccf69,
    name: "privacy-policy___en"
  }, {
    path: "/en/register",
    component: _4af4f990,
    name: "register___en"
  }, {
    path: "/en/terms-of-use",
    component: _2ca36269,
    name: "terms-of-use___en"
  }, {
    path: "/hu/accept-invitation",
    component: _7767c4c8,
    name: "accept-invitation___hu"
  }, {
    path: "/hu/change-password",
    component: _5ec47e94,
    name: "change-password___hu"
  }, {
    path: "/hu/dashboard",
    component: _4bfb39b2,
    name: "dashboard___hu"
  }, {
    path: "/hu/days-off",
    component: _49d6a46a,
    name: "days-off___hu"
  }, {
    path: "/hu/days-off-calculator",
    component: _a6b4de18,
    name: "days-off-calculator___hu"
  }, {
    path: "/hu/forgot-password",
    component: _568237ba,
    name: "forgot-password___hu"
  }, {
    path: "/hu/help",
    component: _058e7713,
    name: "help___hu"
  }, {
    path: "/hu/login",
    component: _09dc01f2,
    name: "login___hu"
  }, {
    path: "/hu/logout",
    component: _97822b48,
    name: "logout___hu"
  }, {
    path: "/hu/privacy-policy",
    component: _32dccf69,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/register",
    component: _4af4f990,
    name: "register___hu"
  }, {
    path: "/hu/terms-of-use",
    component: _2ca36269,
    name: "terms-of-use___hu"
  }, {
    path: "/register/company",
    component: _1fc4cde3,
    name: "register-company___hu___default"
  }, {
    path: "/register/personal-information",
    component: _7e26fcae,
    name: "register-personal-information___hu___default"
  }, {
    path: "/register/team",
    component: _00286832,
    name: "register-team___hu___default"
  }, {
    path: "/user/account-settings",
    component: _13796225,
    name: "user-account-settings___hu___default"
  }, {
    path: "/user/approvers",
    component: _61f95bdc,
    name: "user-approvers___hu___default"
  }, {
    path: "/user/days-off",
    component: _95440f4a,
    name: "user-days-off___hu___default"
  }, {
    path: "/user/export",
    component: _5cab7216,
    name: "user-export___hu___default"
  }, {
    path: "/user/personal-information",
    component: _37f21481,
    name: "user-personal-information___hu___default"
  }, {
    path: "/en/company/administrators",
    component: _ae39462c,
    name: "company-administrators___en"
  }, {
    path: "/en/company/employees",
    component: _8f3ccbde,
    name: "company-employees___en"
  }, {
    path: "/en/company/export",
    component: _70fe8350,
    name: "company-export___en"
  }, {
    path: "/en/company/groups",
    component: _08857478,
    name: "company-groups___en"
  }, {
    path: "/en/company/non-working-days",
    component: _0a70fed2,
    name: "company-non-working-days___en"
  }, {
    path: "/en/company/settings",
    component: _7c2e4447,
    name: "company-settings___en"
  }, {
    path: "/en/register/company",
    component: _1fc4cde3,
    name: "register-company___en"
  }, {
    path: "/en/register/personal-information",
    component: _7e26fcae,
    name: "register-personal-information___en"
  }, {
    path: "/en/register/team",
    component: _00286832,
    name: "register-team___en"
  }, {
    path: "/en/user/account-settings",
    component: _13796225,
    name: "user-account-settings___en"
  }, {
    path: "/en/user/approvers",
    component: _61f95bdc,
    name: "user-approvers___en"
  }, {
    path: "/en/user/days-off",
    component: _95440f4a,
    name: "user-days-off___en"
  }, {
    path: "/en/user/export",
    component: _5cab7216,
    name: "user-export___en"
  }, {
    path: "/en/user/personal-information",
    component: _37f21481,
    name: "user-personal-information___en"
  }, {
    path: "/hu/company/administrators",
    component: _ae39462c,
    name: "company-administrators___hu"
  }, {
    path: "/hu/company/employees",
    component: _8f3ccbde,
    name: "company-employees___hu"
  }, {
    path: "/hu/company/export",
    component: _70fe8350,
    name: "company-export___hu"
  }, {
    path: "/hu/company/groups",
    component: _08857478,
    name: "company-groups___hu"
  }, {
    path: "/hu/company/non-working-days",
    component: _0a70fed2,
    name: "company-non-working-days___hu"
  }, {
    path: "/hu/company/settings",
    component: _7c2e4447,
    name: "company-settings___hu"
  }, {
    path: "/hu/register/company",
    component: _1fc4cde3,
    name: "register-company___hu"
  }, {
    path: "/hu/register/personal-information",
    component: _7e26fcae,
    name: "register-personal-information___hu"
  }, {
    path: "/hu/register/team",
    component: _00286832,
    name: "register-team___hu"
  }, {
    path: "/hu/user/account-settings",
    component: _13796225,
    name: "user-account-settings___hu"
  }, {
    path: "/hu/user/approvers",
    component: _61f95bdc,
    name: "user-approvers___hu"
  }, {
    path: "/hu/user/days-off",
    component: _95440f4a,
    name: "user-days-off___hu"
  }, {
    path: "/hu/user/export",
    component: _5cab7216,
    name: "user-export___hu"
  }, {
    path: "/hu/user/personal-information",
    component: _37f21481,
    name: "user-personal-information___hu"
  }, {
    path: "/en/user/:id?",
    component: _9b561470,
    name: "user-id___en",
    children: [{
      path: "account-settings",
      component: _0fa9865a,
      name: "user-id-account-settings___en"
    }, {
      path: "approvers",
      component: _37805947,
      name: "user-id-approvers___en"
    }, {
      path: "days-off",
      component: _279c2190,
      name: "user-id-days-off___en"
    }, {
      path: "export",
      component: _914736ea,
      name: "user-id-export___en"
    }, {
      path: "personal-information",
      component: _cb49d394,
      name: "user-id-personal-information___en"
    }]
  }, {
    path: "/hu/user/:id?",
    component: _9b561470,
    name: "user-id___hu",
    children: [{
      path: "account-settings",
      component: _0fa9865a,
      name: "user-id-account-settings___hu"
    }, {
      path: "approvers",
      component: _37805947,
      name: "user-id-approvers___hu"
    }, {
      path: "days-off",
      component: _279c2190,
      name: "user-id-days-off___hu"
    }, {
      path: "export",
      component: _914736ea,
      name: "user-id-export___hu"
    }, {
      path: "personal-information",
      component: _cb49d394,
      name: "user-id-personal-information___hu"
    }]
  }, {
    path: "/user/:id?",
    component: _9b561470,
    name: "user-id___hu___default",
    children: [{
      path: "account-settings",
      component: _0fa9865a,
      name: "user-id-account-settings___hu___default"
    }, {
      path: "approvers",
      component: _37805947,
      name: "user-id-approvers___hu___default"
    }, {
      path: "days-off",
      component: _279c2190,
      name: "user-id-days-off___hu___default"
    }, {
      path: "export",
      component: _914736ea,
      name: "user-id-export___hu___default"
    }, {
      path: "personal-information",
      component: _cb49d394,
      name: "user-id-personal-information___hu___default"
    }]
  }, {
    path: "/",
    component: _316a5bf0,
    name: "index___hu___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
