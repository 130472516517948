//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ErrorLayout',
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
};
