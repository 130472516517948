import { disableBodyScroll, enableBodyScroll } from '@/static/scripts/body-scroll-lock.min.js';

export default ({ $device }, inject) => {
    inject('disableBodyScroll', (selector) => {
        if (!$device.isIos) {
            return disableBodyScroll(selector);
        }
    });
    inject('enableBodyScroll', (selector) => {
        if (!$device.isIos) {
            return enableBodyScroll(selector);
        }
    });
};
