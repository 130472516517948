const middleware = {}

middleware['init-authenticated'] = require('../middleware/init-authenticated.js')
middleware['init-authenticated'] = middleware['init-authenticated'].default || middleware['init-authenticated']

middleware['redirect-guest'] = require('../middleware/redirect-guest.js')
middleware['redirect-guest'] = middleware['redirect-guest'].default || middleware['redirect-guest']

middleware['redirect-register'] = require('../middleware/redirect-register.js')
middleware['redirect-register'] = middleware['redirect-register'].default || middleware['redirect-register']

middleware['redirect-user'] = require('../middleware/redirect-user.js')
middleware['redirect-user'] = middleware['redirect-user'].default || middleware['redirect-user']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

export default middleware
