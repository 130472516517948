//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '~/mixins/i18n';
import ToastMessage from '~/components/Generic/ToastMessage';
import DefaultHeader from '~/components/Layout/Headers/DefaultHeader';
import GridOverlay from '~/components/Layout/GridOverlay';
import GenericFooter from '@/components/Layout/Footers/GenericFooter.vue';
import CookieBanner from '@/components/Layout/CookieBanner.vue';

export default {
    name: 'RegisterLayout',
    components: {
        DefaultHeader,
        GenericFooter,
        GridOverlay,
        ToastMessage,
        CookieBanner,
    },
    mixins: [i18n],
    middleware: ['redirect-register'],
};
